<template>
    <div class="edit-fee-preferences">
        <v-row justify="center">
            <v-col md=8>
                <!-- <h3 class="mx-2 text-left">Edit Fee Payment Details</h3> -->
                <div v-if="preferences" class="mx-2 my-2 quill-editor">
                    <quill
                        :content="preferences.content"
                        class="editor"
                        :options="editorOption"
                        @change="onEditorChange($event)"
                        
                  >
                  </quill>

                  <v-btn color="primary" :disabled="!isChanged" class="my-2" block @click="submitFeePreferences">Save</v-btn>
                        
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Mixins from "@utils/mixins";
import { mapGetters } from 'vuex';


export default{
    name: 'EditFeePreferences',
    mixins: [Mixins.essentials, Mixins.pullToRefresh],
    components:{
        quill: quillEditor,
    },
    data(){
        return {
            preferences: null,
            isChanged: false,
            contentQuillObj: { delta: null, html: null },
            editorOption: {
                theme: 'snow',
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline",],
                        // ["blockquote", "code-block"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        // [{ script: "sub" }, { script: "super" }],
                        // [{ indent: "-1" }, { indent: "+1" }],
                        // [{ direction: "rtl" }],
                        [{ size: ["small", false, "large", "huge"] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        // [{ font: [] }],
                        [
                            { color: [] }, 
                            // { background: [] }
                        ],
                        [{ align: [] }],
                        // ["clean"],
                        [
                            "link", 
                            "image", 
                            // "video"
                        ],
                    ],
                },
            },

        }
    },
    computed:{
        ...mapGetters(["currentInstitution", "currentUser"]),
    },
    methods:{
        async getInstitutionFeePreferences() {
            var url = this.endpoints.institutionFeePreferences + this.currentInstitution.id + "/";
            url = this.Helper.addUrlParams(url, ['detailed=true'])
            this.preferences = await this.api.call(this.essentials, url);
        },

        onEditorChange({ quill, html, text }) {
            console.log("editor change!", quill, html, text);
            this.isChanged = true;
            this.preferences.content = html;
            this.contentQuillObj.delta = quill.editor.delta;
            this.contentQuillObj.html = html;
        },

        async submitFeePreferences(){
            var url = this.endpoints.institutionFeePreferences + this.currentInstitution.id + "/";
            this.preferences["contentQuillObj"] = this.contentQuillObj;
            this.preferences['editor'] = this.currentUser.id;
            // this.preferences['institution'] = this.currentInstitution.id;
            var post_body = this.preferences;
            var response = await this.api.call(
                this.essentials,
                url,
                this.api.Methods.PUT,
                post_body
            )
            if (response) {
                this.showSnackbar({
                    title: 'Fee payment details saved successfully.',
                    type: 'success'
                });
                setTimeout(() => {
                    this.$router.replace({ name: "FeePreferences" });
                }, 1000);
            }
            
        },
    },
    created(){
        this.getInstitutionFeePreferences();
    }
}
</script>
